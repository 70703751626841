<template>
	<div class="page">
		<div class="toolbar" ref="toolbar">
			<div class="type"> 新增站点 </div>
			<div class="action-box">
				<el-button @click="save('form')" size="small" icon="el-icon-check" plain
					v-if="$hasAccess('device.group/add')">保存 </el-button>
				<el-button @click="close" size="small" icon="el-icon-close" plain>取消 </el-button>
			</div>
		</div>
		<el-card class="box-card card">
			<el-form :model="formData" :rules="rules" ref="form" label-width="120px">
				<el-row>
					<el-col :xs="24" :sm="10">
						<!-- 小区信息 -->
						<div class="el-block">
							<div class="row-title">站点信息</div>
							<el-form-item label="小区名称" prop="group_name">
								<el-input v-model="formData.group_name" clearable />
							</el-form-item>
							<el-form-item label="选择地址" prop="city">
								<div>
									<el-button type="text" circle icon="el-icon-location-outline" @click="openMap">选择
									</el-button>
									<span>{{formData.province}}-{{formData.city}}-{{formData.county}}</span>
								</div>
							</el-form-item>
							<el-form-item label="详细地址" prop="address">
								<el-input v-model="formData.address" clearable />
							</el-form-item>
							<el-form-item label="站点图片" prop="attach_id">
								<upload :fileList="imageList" @getFileInfo="getFileInfo">
								</upload>
							</el-form-item>
							<!--							<el-form-item label="站点类型" prop="station_type">-->
							<!--								<el-select v-model="formData.station_type" clearable="" placeholder="请选择站点类型" style="width:100%">-->
							<!--									<el-option v-for="type in station_type" :key="type.type_id" :label="type.type_name" :value="type.type_id">-->
							<!--									</el-option>-->
							<!--								</el-select>-->
							<!--							</el-form-item>-->
							<!--							<el-form-item label="建设场所" prop="construction">-->
							<!--								<el-select v-model="formData.construction" clearable="" placeholder="请选择建设场所" style="width:100%">-->
							<!--									<el-option v-for="type in construction" :key="type.type_id" :label="type.type_name" :value="type.type_id">-->
							<!--									</el-option>-->
							<!--								</el-select>-->
							<!--							</el-form-item>-->
						</div>
						<!-- 模式信息 -->
						<div class="el-block">
							<div class="row-title">模式信息</div>
							<el-form-item label="是否隐藏" prop="hide_status">
								<el-radio-group v-model="formData.hide_status">
									<el-radio :label="10">否</el-radio>
									<el-radio :label="20">是</el-radio>
								</el-radio-group>
							</el-form-item>
							<el-form-item label="钉钉报警" prop="fee_type">
								<el-radio-group v-model="formData.alarm_status">
									<el-radio :label="10">报警</el-radio>
									<el-radio :label="20">屏蔽</el-radio>
								</el-radio-group>
							</el-form-item>
							<el-form-item label="电价" prop="ele_price">
								<el-input-number :precision="4" :min="0" :step="0.1" v-model="formData.ele_price"
									clearable />
							</el-form-item>
							<el-form-item label="服务费" prop="service_price">
								<el-input-number :precision="4" :min="0" v-model="formData.service_price" clearable />
							</el-form-item>
							<div class="agent-choose-form">
								<div class="title">
									<span class="tips">服务费折扣时段</span>
									<el-button class="add-button" icon="el-icon-plus" type="primary" size="small"
										@click="onDiscountItemAdd">增加</el-button>
								</div>
								<div v-for="idx in groupDiscountList.length" class="agent-item" :key="idx">
									<el-input v-model="groupDiscountList[idx-1].time_slot"
										style="width: 100px;margin-right: 10px" clearable />
									<el-input v-model="groupDiscountList[idx-1].time_slot_2" style="width: 100px;"
										clearable />
									<el-input-number type="number" :min="0" :max="10" :step="0.1" :precision="1"
										v-model="groupDiscountList[idx-1].discount" class="input" size="small"
										placeholder="请输入折扣"></el-input-number>
									<el-button class="del-button" size="small" type="danger" icon="el-icon-delete"
										@click="onDiscountItemDel(idx)">删除</el-button>
								</div>
								<!-- 								<div class="agent-choose-error-tips">
									<span v-show="cooperateAgentErrTips">* 代理商分成比例累计需要为100%</span>
								</div> -->
							</div>
							<!--							<el-form-item label="折扣" prop="discount">-->
							<!--								<el-input-number :min="0" :max="9.5" :step="0.1" :precision="1" v-model="formData.discount" clearable />-->
							<!--							</el-form-item>-->
							<!--							<el-form-item label="费率" prop="fee_id">-->
							<!--								<el-select v-model="formData.fee_id" placeholder="请选择费率" clearable style="width:100%">-->
							<!--									<el-option v-for="fee in feeList" :key="fee.fee_id" :label="fee.fee_name" :value="fee.fee_id">-->
							<!--									</el-option>-->
							<!--								</el-select>-->
							<!--							</el-form-item>-->
							<el-form-item label="随机立减" prop="allow_short">
								<el-radio-group v-model="formData.allow_short">
									<el-radio :label="10">关</el-radio>
									<el-radio :label="20">开</el-radio>
								</el-radio-group>
							</el-form-item>
							<el-form-item label="单价" prop="end_price" v-if="formData.allow_short==20">
								<el-input-number :precision="4" :min="0" :step="0.1" v-model="formData.end_price"
									clearable />
							</el-form-item>
							<el-form-item label="退款至微信" prop="refund_chat">
								<el-radio-group v-model="formData.refund_chat">
									<el-radio :label="10">关</el-radio>
									<el-radio :label="20">开</el-radio>
								</el-radio-group>
							</el-form-item>
							<el-form-item label="能否使用优惠券" prop="refund_chat">
								<el-switch v-model="formData.useCoupon" active-text="开" inactive-text="关" :active-value="1" :inactive-value="0" />
							</el-form-item>
							<el-form-item label="能否使用企业卡" prop="refund_chat">
								<el-switch v-model="formData.useQy" active-text="开" inactive-text="关" :active-value="1" :inactive-value="0" />
							</el-form-item>
							<el-form-item label="能否使用电卡" prop="refund_chat">
								<el-switch v-model="formData.useCard" active-text="开" inactive-text="关" :active-value="1" :inactive-value="0" />
							</el-form-item>
						</div>
					</el-col>
					<!-- 副栏 -->
					<el-col :xs="{span:24,offset:0}" :sm="{span:10,offset:2}">
						<!-- 代理信息 -->
						<!--div class="el-block">
							<div class="row-title">代理信息</div>
							<div class="agent-choose-form">
								<div class="title">
									<span class="tips">代理商选择</span>
									<el-button class="add-button" icon="el-icon-plus" type="primary" size="small"
										@click="onAgentItemAdd">增加代理</el-button>
								</div>
								<div v-for="idx in cooperateAgentList.length" class="agent-item">
									<el-select v-model="cooperateAgentList[idx-1].agent_id" class="select" size="small"
										placeholder="请选择代理商" clearable>
										<el-option v-for="item in agentList" :key="item.agent_id"
											:label="item.agent_name" :value="item.agent_id">
										</el-option>
									</el-select>
									<el-input-number type="number" :min="0" :max="1" :step="0.1" :precision="2"
										v-model="cooperateAgentList[idx-1].ratio" class="input" size="small"
										placeholder="请输入分成比例"></el-input-number>
									<el-button class="del-button" size="small" type="danger" icon="el-icon-delete"
										@click="onAgentItemDel(idx)">删除</el-button>
								</div-->
								<!-- 								<div class="agent-choose-error-tips">
									<span v-show="cooperateAgentErrTips">* 代理商分成比例累计需要为100%</span>
								</div> -->
							<!--/div>
							<el-form-item label="合作模式" prop="cooperate_model">
								<el-radio-group v-model="formData.cooperate_model">
									<el-radio :label="10">买电</el-radio>
									<el-radio :label="20">分成-充值充电</el-radio>
									<el-radio :label="30">分成-充电</el-radio>
								</el-radio-group>
							</el-form-item>
							<el-form-item label="合作规则" prop="cooperate_rule">
								<el-row>
									<el-col :span="11" v-if="formData.cooperate_model == 10">
										<el-tooltip class="item" effect="dark" content="电价" placement="bottom-end">
											<el-input-number v-model="cooperate_rule.buy" controls-position="right"
												:min="0" :max="100" :step="0.1" :precision="2" placeholder="电价"
												style="width:100%">
											</el-input-number>
										</el-tooltip>
									</el-col>
									<el-col :span="11" v-if="formData.cooperate_model == 20">
										<el-tooltip class="item" effect="dark" content="充值分成" placement="bottom-end">
											<el-input-number v-model="cooperate_rule.recharge" controls-position="right"
												:min="0" :max="1" :step="0.1" :precision="2" placeholder="充值分成"
												style="width:100%"></el-input-number>
										</el-tooltip>
									</el-col>
									<el-col :span="11" v-if="formData.cooperate_model == 30">
										<el-tooltip class="item" effect="dark" content="充电分成" placement="bottom-end">
											<el-input-number v-model="cooperate_rule.order" controls-position="right"
												:min="0" :max="1" :step="0.1" :precision="2" placeholder="充电分成"
												style="width:100%"></el-input-number>
										</el-tooltip>
									</el-col>
								</el-row>
							</el-form-item>
						</div-->
						<!-- 优惠信息 -->
						<div class="el-block">
							<div class="row-title">其他信息</div>
							<el-form-item label="绑定车牌提示" prop="park_hour">
								<el-input-number :precision="0" :min="0" :step="1" v-model="formData.park_hour" clearable />小时
							</el-form-item>
							<el-form-item label="场站状态" prop="fee_type">
								<el-radio-group v-model="formData.status">
									<el-radio :label="1">正常</el-radio>
									<el-radio :label="2">建设中</el-radio>
									<el-radio :label="3">维护中</el-radio>
								</el-radio-group>
							</el-form-item>
							<el-form-item label="公告" prop="notice">
								<el-input v-model="formData.notice" clearable />
							</el-form-item>
							<el-form-item label="停车信息" prop="parking">
								<el-input v-model="formData.parking" clearable />
							</el-form-item>
							<el-form-item label="占位费" prop="occ_price">
								<el-input-number :precision="4" v-model="formData.occ_price" clearable />
							</el-form-item>
							<el-form-item label="标签" prop="label_id">
								<el-select v-model="formData.label_id" placeholder="请选择标签" clearable multiple
									style="width:100%">
									<el-option v-for="item in label" :key="item.label_id" :label="item.label_name"
										:value="item.label_id">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="停车出场" prop="park_status">
								<el-radio-group v-model="formData.park_status">
									<el-radio :label="10">开</el-radio>
									<el-radio :label="20">关</el-radio>
								</el-radio-group>
							</el-form-item>
							<el-form-item label="图片" prop="attach_id_group" v-if="formData.park_status==10">
								<upload :fileList="imagesList" @getFileInfo="getFilesInfo">
								</upload>
							</el-form-item>
							<el-form-item label="数据统计" prop="monitor">
								<el-switch v-model="formData.monitor" active-text="开" inactive-text="关" :active-value="1" :inactive-value="0" />
							</el-form-item>
							<el-form-item label="站长" prop="manager_phone">
								<el-select v-model="formData.manager_phone" placeholder="请选择站长" filterable clearable size="small" @change="changeManager">
									<el-option v-for="item in managerList" :key="item.login_id" :label="item.nickname" :value="item.login_id">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="运营" prop="operator_phone">
								<el-select v-model="formData.operator_phone" placeholder="请选择运营" filterable clearable size="small" @change="changeOperator">
									<el-option v-for="item in managerList" :key="item.login_id" :label="item.nickname" :value="item.login_id">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="代理商名称" prop="run_type">
								<el-select v-model="formData.run_type" placeholder="请选择代理商" style="width:40%">
									<el-option label="叮当自营" :value="1"></el-option>
									<el-option label="代理商" :value="2"></el-option>
								</el-select>
								<el-input v-if="formData.run_type == 2" v-model="formData.run_name" style="width:60%;margin-left: 10px;" placeholder="请输入代理商名" />
							</el-form-item>
<!--							<el-form-item label="底层平台" prop="hlht">-->
<!--								<el-select v-model="formData.hlht" clearable="" placeholder="请选择底层平台" style="width:100%">-->
<!--									<el-option v-for="type in hlht" :key="type.type_id" :label="type.type_name" :value="type.type_id">-->
<!--									</el-option>-->
<!--								</el-select>-->
<!--							</el-form-item>-->
							<el-form-item label="建设场所" prop="build_ground">
								<el-select v-model="formData.build_ground" placeholder="请选择建设场所" style="width:40%" clearable >
									<el-option v-for="(v, k) in build_grounds" :label="v" :value="parseInt(k)" :key="k"></el-option>
								</el-select>
							</el-form-item>
						</div>
					</el-col>
				</el-row>
			</el-form>
		</el-card>
		<div v-if="showMap">
			<BMap @mapChooseComplete="handleMapComplete" @mapClose="handleMapClose"></BMap>
		</div>
	</div>
</template>
<script>
	import API from '@/service/api'
	import {
		mapMutations
	} from 'vuex'
	import BMap from '@/components/b-map'
	import upload from '@/components/upload'
	import {
		bd2gd,
		gd2bd
	} from '../../../utils/util'
	import { build_grounds } from './const'

	export default {
		name: 'device-group-add',
		components: {
			BMap,
			upload
		},
		data() {
			return {
				// 表单信息
				formData: {
					status: 1,
					cooperate_rule: '',
					cooperate_model: 20,
					allow_short: 10,
					refund_chat: 10,
					alarm_status: 10,
					hide_status: 10,
					park_status: 20,
					attach_id: 0,
					park_hour: 0,
					attach_id_group: 0,
					useCoupon:1,
					useQy:1,
					useCard:1,
				},
				imageList: [],
				imagesList: [],
				// 代理商分成数组
				cooperateAgentList: [{
					agent_id: 0,
					ratio: 1
				}],
				// 代理商分成数组
				groupDiscountList: [{
					time_slot: 0,
					time_slot_2: 24,
					discount: 1
				}],
				// 特殊字段-合作规则
				cooperate_rule: {
					order: 0,
					recharge: 0,
					buy: 0
				},
				// 特殊字段-充值优惠
				plan_id: '',
				label_name: '',
				// 选项列表
				feeList: [],
				agentList: [],
				planList: [],
				giftList: [],
				managerList: [], //管理员列表
				// 规则
				rules: {
					group_name: [{
						required: true,
						message: '请输入站点名称',
						trigger: 'change'
					}],
					allow_short: [{
						required: true,
						message: '请选择是否允许临时充电',
						trigger: 'change'
					}],
					refund_chat: [{
						required: true,
						message: '请选择是否退款至微信',
						trigger: 'change'
					}],
					cooperate_model: [{
						required: true,
						message: '请选择合作模式',
						trigger: 'change'
					}],
					fee_id: [{
						required: true,
						message: '请选择费率',
						trigger: 'change'
					}],
					city: [{
						required: false,
						message: '请选择地址',
						trigger: 'change'
					}],
					address: [{
						required: false,
						message: '请输入详细地址',
						trigger: 'change'
					}]
				},
				showMap: false,
				completedDate: '',
				// 设备类型
				label: [],
				// 站点类型
				station_type: [{
					type_id: 1,
					type_name: "公共",
				}, {
					type_id: 50,
					type_name: "个人",
				}, {
					type_id: 100,
					type_name: "公交（专用）",
				}, {
					type_id: 101,
					type_name: "环卫（专用）",
				}, {
					type_id: 102,
					type_name: "物流（专用）",
				}, {
					type_id: 103,
					type_name: "出租车（专用）",
				}, {
					type_id: 255,
					type_name: "其他",
				}, ],
				// 建设场所
				construction: [{
					type_id: 1,
					type_name: "居民区",
				}, {
					type_id: 2,
					type_name: "公共机构",
				}, {
					type_id: 3,
					type_name: "企事业单位",
				}, {
					type_id: 4,
					type_name: "写字楼",
				}, {
					type_id: 5,
					type_name: "工业园区",
				}, {
					type_id: 6,
					type_name: "交通枢纽",
				}, {
					type_id: 7,
					type_name: "大型文体设施",
				}, {
					type_id: 8,
					type_name: "城市绿地",
				}, {
					type_id: 9,
					type_name: "大型建筑配建停车场",
				}, {
					type_id: 10,
					type_name: "路边停车位",
				}, {
					type_id: 11,
					type_name: "城际髙速服务区",
				}, {
					type_id: 255,
					type_name: "其他",
				}],
				// 设备类型
				hlht: [{
					type_id: 0,
					type_name: "星星",
				}, {
					type_id: 1,
					type_name: "云快充",
				}, {
					type_id: 2,
					type_name: "达客云",
				}],
				build_grounds:build_grounds,
			}
		},
		mounted() {
			this.init()
		},
		watch: {
			"cooperate_rule.recharge"() {
				this.cooperate_rule.order = this.cooperate_rule.recharge;
			},
			"cooperate_rule.order"() {
				this.cooperate_rule.recharge = this.cooperate_rule.order;
			},
		},
		async activated() {
			this.init()
		},
		methods: {
			...mapMutations(['setTabList']),
			// 初始化请求
			init() {
				this.getAgentList()
				this.getPlanList()
				//获取erp管理员列表
				this.$api.Device.managerList({token: this.$store.state.user.token}).then((res)=>{
					this.managerList = res;
				})
			},
			// 获取标签内容
			async getPlanList() {
				const params = {
					token: this.$store.state.user.token,
					page: 1,
					size: 100
				}
				const res = await this.$api.Device.GetLabelList(params, {})
				this.label = res;
			},
			// 获取代理商信息
			async getAgentList() {
				const params = {
					token: this.$store.state.user.token,
					status: 40,
					page: 1,
					size: 100
				}
				const ziying = {
					agent_id: 0,
					agent_name: "自营",
				};
				const res = await this.$api.Agent.GetAgentList(params, {})
				res.data.unshift(ziying);
				this.agentList = res.data
			},
			// 保存
			save(formName) {
				if (!this.checkChooseAgentList()) return;
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.formData.agent_ids = this.cooperateAgentList.map(item => item.agent_id).join(',');
						this.formData.agent_data = JSON.stringify(this.cooperateAgentList);
						this.formData.cooperate_rule = JSON.stringify(this.cooperate_rule);
						this.formData.group_data = JSON.stringify(this.groupDiscountList);
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(this.formData)
						}
						await API.Device.AddGroup(params)
						this.$notify({
							title: '成功',
							message: '新建成功',
							type: 'success'
						})
						this.closeTab()
					}
				})
			},
			// 关闭
			close() {
				this.$confirm('确定要关闭当前页面吗', '提示', {
					cancelButtonClass: 'btn-custom-cancel',
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.closeTab()
				})
			},
			// 关闭tab
			closeTab() {
				let tabs = this.$store.state.app.tabList
				this.setTabList(tabs.filter((tab) => tab.name !== 'device-group-add'))
				this.$router.push({
					name: 'device-group-list'
				})
			},
			// 打开地图
			openMap() {
				this.showMap = true
			},
			// 地图选择回调
			handleMapComplete(address) {
				this.showMap = false
				// this.formData.province = address.addressComponents.province
				// this.formData.city = address.addressComponents.city
				// this.formData.county = address.addressComponents.district
				// const point = bd2gd(address.point.lng, address.point.lat)
				this.formData.province = address.province;
				this.formData.city = address.city;
				this.formData.county = address.district;
				const point = bd2gd(address.lng, address.lat);
				this.formData.longitude = point.lng;
				this.formData.latitude = point.lat;
				this.formData.city_code = '';
				// console.log("province", address.province);
				// console.log("city", address.city);
				// console.log("district", address.district);
				// console.log("lng bd", address.lng);
				// console.log("lng gd", point.lng);
				// console.log("lat bd", address.lat);
				// console.log("lat gd", point.lat);
			},
			// 附件
			getFileInfo(fileInfo) {
				if (this.formData.attach_id && this.formData.attach_id == fileInfo && this.formData.attach_id.indexOf(
						",") == "-1") {
					//执行删除
					const params = {
						token: this.$store.state.user.token,
						attach_id: this.formData.attach_id
					}
					this.$api.Ad.DeleteImg(params);
					this.formData.attach_id = "";
					return;
				}
				this.formData.attach_id = fileInfo;
			},
			// 附件
			getFilesInfo(fileInfo) {
				if (this.formData.attach_id_group && this.formData.attach_id_group == fileInfo && this.formData
					.attach_id_group.indexOf(",") == "-1") {
					//执行删除
					const params = {
						token: this.$store.state.user.token,
						attach_id: this.formData.attach_id_group
					}
					this.$api.Ad.DeleteImg(params);
					this.formData.attach_id_group = "";
					return;
				}
				this.formData.attach_id_group = fileInfo;
			},
			// 地图关闭回调
			handleMapClose() {
				this.showMap = false
			},
			onAgentItemAdd: function() {
				this.cooperateAgentList.push({
					agent_id: null,
					ratio: null
				});
			},
			onAgentItemDel: function(idx) {
				this.cooperateAgentList.splice(idx - 1, 1);
			},
			onDiscountItemAdd: function() {
				this.groupDiscountList.push({
					time_slot: null,
					time_slot_2: null,
					discount: null
				});
			},
			onDiscountItemDel: function(idx) {
				this.groupDiscountList.splice(idx - 1, 1);
			},
			checkChooseAgentList: function() {
				let totalRatio = 0;
				this.cooperateAgentList.forEach(item => {
					if (item.agent_id != null) {
						totalRatio += item.ratio
					}
				})
				if (totalRatio !== 1) {
					this.$notify.error("代理商分成比例累计需要为100%！")
				}
				return totalRatio === 1;
			},
			changeManager(e){ //修改站长
				for(let v of this.managerList){
					if(v.login_id == e){
						this.formData.manager = v.nickname;
						break;
					}
				}
			},
			changeOperator(e){ //修改运营
				for(let v of this.managerList){
					if(v.login_id == e){
						this.formData.operator = v.nickname;
						break;
					}
				}
			}
		}
	}
</script>
<style lang="scss">
	.toolbar {
		display: flex;
		justify-content: space-between;
		position: relative;
		min-height: 40px;

		.type {
			font-size: 16px;
			display: flex;
			align-items: center;
		}

		.action-box {
			line-height: 40px;
			height: 40px;
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			z-index: 9;
		}
	}

	.agent-choose-form {
		padding-left: 40px;

		.title {
			.tips {
				font-size: 14px;
				color: #606266;
			}

			.add-button {
				margin-left: 20px;
			}
		}

		.agent-item {
			display: flex;
			width: 100%;
			padding: 10px 10px;

			.select {
				margin-left: 10px;
				width: 250px;
			}

			.input {
				margin-left: 10px;
				width: 250px;
			}

			.del-button {
				margin-left: 10px;
			}
		}

		.agent-choose-error-tips {
			margin-left: 20px;
			height: 20px;

			span {
				color: #F56C6C;
				font-size: 14px;
			}
		}
	}
</style>
